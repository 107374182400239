.leaderboarHeading{
    color: var(--grey-color);
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
}
.leaderboardToggle{
    margin-bottom: 1rem;
}
.thisweek,
.lastweek{
    border: none;
    width: 10rem;
    color: var(--white-color);
    padding: 0.6rem;
    font-size: 0.8rem;
    font-weight: 900;
}
.thisweek{
    border-radius: 1rem 0 0 1rem;
    margin-right: 0.3rem;
    background-color: var( --yellow-color);
}
.lastweek{
    border-radius: 0px 1rem 1rem 0 ;
    border:1px solid var(--red-color);
    background-color: var(--white-color);
    color: var(--red-color);
}
.thisweekActive{
    border: none;
    width: 10rem;
    color: var(--yellow-color);
    padding: 0.6rem;
    font-size: 0.8rem;
    font-weight: 900;
    border-radius: 1rem 0 0 1rem;
    border: 1px solid var(--yellow-color);
    margin-right: 0.3rem;

}
.activeWeek{
    border: none;
    width: 10rem;
    color: var(--white-color);
    padding: 0.6rem;
    font-size: 0.8rem;
    font-weight: 900;
    border-radius: 0px 1rem 1rem 0 ;
    background-color: var( --red-color);
   

}
.leaderboard{
    background-color: var(--white-color);
    width: 100%;
    /* width: 428px; */
    border-radius: 1.5rem 1.5rem 0 0;
    text-align: center;
    padding-top: 1.2rem;
    margin-top: 0.8rem;

}
.winnersSection{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-color);
  
}
.imgCtn{
    width: 3.9rem;
    height: 3.9rem;
    border: 1px solid var( --orange-color);
    border-radius: 100%;
}
.winnersAvatar{
    width: 90%;
    height: 90%;
}
.winner{
    width: 4.9rem;
    height: 4.9rem;
    border: 1px solid var( --orange-color);
    border-radius: 100%;
    margin:0 15px 0 15px;
}
.position,
.winnersName{
    font-weight: 900;
    font-size: 0.8rem;

}
.playersSection{
    width: 100%;
    text-align: center;
    /* padding: 0.7rem; */
    /* text-align: center; */
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  

}
.players{
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 90%;
    height: 48px;
    border-radius: 15px;
    margin:20px 0 0 0;
    /* padding:0.6rem 0; */
    background-color: var(--pink)
}
.playersPosition{
    margin-left: 15px;
    font-weight: 900;
    font-size: 0.9rem;
  
}
.playersName,
.playersPoint,
.playedDate{
    margin-right: 1rem;
    font-weight: 700;
    font-size: 0.9rem;
}
.playersName{
    width: 7rem;
}
.playedDate{
    width: 15rem;
    margin: 0 !important;
    padding: 0 !important;
    
}
.playersPoint{
    width: 2rem;
    text-align: center;
}
.leaderboardDataError{
    font-size: 0.8rem;
    color: var(--grey-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .Leaderboard-loaderWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background-color: red;
   
  } */
  .loadingstate{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: var(--orange-color);
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
}
@media screen and (min-width: 600px) {
    .players{
    /* justify-content: center; */
    align-items:center;
    }
  }


