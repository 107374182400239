/* TOP NAV */
.TopNav {
  /* min-height: 9vh; */
  /* width: 88vw;
  margin: auto; */
  padding: 10px;
}

.TopNav ul {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  object-fit: contain;
  background-color: var(--green-color);
  border-radius: 50%;
  padding: 3px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}

.burger {
  cursor: pointer;
  z-index: 5;
  transition: all 0.3s ease;
  background-color: var(--white-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  position: relative;
}

.burger span {
  width: 18px;
  height: 2px;
  background: var(--red-color);
  margin: 4.5px;
  display: block;
  border-radius: 10px;
}

.burger span:nth-child(2) {
  width: 10px;
}

.userProfile {
  display: flex;
  flex-direction: center;
  align-items: center;
  gap: 10px;
}

.userProfile span {
  font-weight: 750;
  font-size: 1.25rem;
  color: var(--grey-color);
}

.burgerMenu {
  width: 160px;
  margin-top: 20px;
  display: flex;
  margin: auto;
  flex-direction: column;
  font-size: 14px;
  padding: 15px 10px;
  background-color: #fefefe;
  color: #707070;
  gap: 12px;
  position: absolute;
  top: 39px;
  right: 0;
  border-radius: 8px;
  font-weight: 750;
}

.burgerMenu a {
  color: #707070;
}

.burgerMenu a:hover,
.activeNavLink {
  color: var(--green-color);
}

/* BANNER */
.banner {
  background-color: var(--yellow-color);
  /* color: var(--white-color); */
  color: #000;
  height: 40px;
  display: grid;
  place-items: center;
  margin-top: 10px;
}

.banner span {
  font-size: 0.75rem;
  font-weight: 900;
}
.navFlex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link {
  color: #000;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .TopNav .burger {
    display: none;
  }
  .TopNav .avatar,
  .userProfile {
    display: none;
  }
}
